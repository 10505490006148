import React, { useEffect, useState } from 'react';
import axios,{ AxiosError } from 'axios';
import config from '../../config';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Collection from '../Collection';
import {ALL_CATEGORY_ID, FREE_CATEGORY_ID} from "./constants";
import BasicModal from "../../components/BasicModal";
import Button from "@mui/material/Button";

const Catalog: React.FC = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | string>(ALL_CATEGORY_ID);
  const [selectedtagline, setSelectedtagline] = useState<string | null>(null);
  const [collection, setCollection] = useState<any | null>(null);
  const [boughtcollection, setBoughtcollection] = useState<any[]>([]);
  const [allCollections, setAllCollections] = useState<any[]>([]);
  const [showAllCollections, setShowAllCollections] = useState<boolean>(false);
  const [showFreeCollections, setShowFreeCollections] = useState<boolean>(false);

  // Used for download confirmation modal
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);
  const [downloadCollectionID, setDownloadCollectionID] = useState<string | number>("");

  const [status_sub,setStatussub] = useState<boolean>(false)
  const [image_path,setImagepath] = useState<string>('')

  const navigate = useNavigate();
  
  interface ErrorResponse {
    [key: string]: string; // Index signature for dynamic property names with string values
  }
  

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/categories/`);
        setCategories([{ id: ALL_CATEGORY_ID, name: 'All' }, { id: FREE_CATEGORY_ID, name: 'Free' }, ...response.data]); // Add "All" category
        console.log(response.data)
        console.log('====',categories,'=====')
      } catch (error) {
        console.error('Failed to fetch categories', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    console.log('Categories state:', categories); // Log state after fetch
  }, [categories]);


  useEffect(() => {
    const status_subs = localStorage.getItem('status');
    if (status_subs == 'true')
      setStatussub(true)
    else
      setStatussub(false)

    if ([ALL_CATEGORY_ID, FREE_CATEGORY_ID].includes(selectedCategory.toString())) {
      // Fetch all collections when "All" is selected
      const fetchAllCollections = async () => {
        try {
          const response = await axios.get(`${config.apiBaseUrl}/all-collections/`);
          setAllCollections(response.data);
          console.log(response.data)
          if (selectedCategory === ALL_CATEGORY_ID) {
            setShowAllCollections(true);
            setShowFreeCollections(false);
          } else if (selectedCategory === FREE_CATEGORY_ID) {
            setShowAllCollections(false);
            setShowFreeCollections(true);
          }
          setImagepath('')
          setCollection(null); // Clear current collection data
        } catch (error) {
          console.error('Failed to fetch all collections', error);
        }
      };

      fetchAllCollections();
    } else if (selectedCategory) {
      setShowAllCollections(false);
      setShowFreeCollections(false);
      const fetchLatestCollection = async () => {
        
        try {
          const email = localStorage.getItem('email');
          const response = await axios.get(`${config.apiBaseUrl}/latest-collection/${selectedCategory}/`,
            { headers: {
            'X-User-Email': email, // Send email in the headers
          }});
          setCollection(response.data['latest_collection']);
          setBoughtcollection(response.data['bought_collections']);
          console.log(response.data)
          console.log('=====',Collection)
          if (response.data['latest_collection']['image']){

            setImagepath(response.data['latest_collection']['image'])
          }
          else{
            setImagepath('')
          }
        } catch (error) {
          console.error('Failed to fetch latest collection', error);
        }
      };

      fetchLatestCollection();
    }
  }, [selectedCategory]);

  const handleCategoryClick = (categoryId: number | string,tagline:string | null) => {
    setSelectedCategory(categoryId);
    setSelectedtagline(tagline);
    console.log('======-----',tagline)
    console.log('======-----',categoryId)

    setCollection(null); // Clear previous collection data
  };

  const handleDownloadPreviouslyPurchasedEdition = async (collection_Id: string) => {
    try {
      const collectionId = collection_Id; 
      const email = localStorage.getItem('email');
      console.log('===========',collectionId)
      const response = await axios.post(`${config.apiBaseUrl}/download-bought-products/`,
        { collection_id: collectionId },
        {
          headers: {
            'X-User-Email': email, // Add email to headers
          },
          responseType: 'blob' // Set responseType to blob for file download
        }
      );

      // Create a URL for the file and initiate download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'products.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.error('Failed to download products CSV', error);
    }
  };
  const handleDownloadCurrentEdition = async (collectionId: string) => {
    try {
      console.log('==========', collectionId);
      const email = localStorage.getItem('email');
  
      const response = await axios.post(`${config.apiBaseUrl}/download-products/`,
        { collection_id: collectionId, catelog_id: 'none' },
        {
          headers: {
            'X-User-Email': email, // Add email to headers
          },
          responseType: 'blob', // Expect a blob for file download or JSON error
        }
      );
  
      // Create a URL for the blob and check content type for file or error
      const contentType = response.headers['content-type'];
  
      if (contentType.includes('application/json')) {
        // Convert blob to JSON text (error case)
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorText = reader.result as string;
            const errorData = JSON.parse(errorText); // Parse JSON error
  
            // Display error messages using toast
            Object.keys(errorData).forEach((key) => {
              // console.log(errorData['key'])
              toast.error(`${key}: ${errorData[key]}`);
            });
          } catch (e) {
            toast.error('Failed to parse error response.');
          }
        };
        reader.readAsText(response.data); // Read the blob as text
      } else if (contentType.includes('text/csv')) {
        // If the content type is CSV, process the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'products.csv'); // Specify file name
        document.body.appendChild(link);
        link.click();
        link.remove();
  
        // Revoke the URL to free up memory
        window.URL.revokeObjectURL(url);
        toast.success('Successfully downloaded pricelist');
      } else {
        // Handle unexpected content types
        toast.error('Unknown response format!');
      }
  
    } catch (error) {
      const err = error as AxiosError;
      console.error('Failed to download products CSV', err);
  
      if (err.response && err.response.data) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorText = reader.result as string;
            const responseData = JSON.parse(errorText); // Parse JSON error
  
            // Display error messages using toast
            Object.keys(responseData).forEach((key) => {
              toast.error(`${responseData[key]}`);
            });
          } catch (e) {
            toast.error('Failed to parse error response.');
          }
        };
        reader.readAsText(err.response.data as Blob); // Read the error blob as text
      } else {
        toast.error('Not Subscribed');
      }
    }
  };

  const handleOpenDownloadModal = (collectionId: string) => {
    setDownloadCollectionID(collectionId)
    setIsDownloadModalOpen(true);
  }

  // Handling of buy to navigate to subscription

  const handleBuy = async () => {
    
    
    
    navigate('/dashboard/subscriptions');
  };
  const handleDownloadModalConfirmation = async () => {
    setIsDownloadModalOpen(false);
    await handleDownloadCurrentEdition(downloadCollectionID.toString());
  }

  const handleDownloadModalCancellation = () => {
    setIsDownloadModalOpen(false);
    navigate("/dashboard/userfactor")
  }
  

  // Find the name of the selected category
  const activeCategoryName = categories.find(category => category.id === selectedCategory)?.name || 'All';

  const renderContents = () => {
    if (showAllCollections) {
      return (<>
        <h2 className="catalog-title">All Collections</h2>
        <div className="catalog-items">
          {allCollections.filter((c) => !c.is_free).map((collection) => (
              <div className="catalog-item" key={collection.id}>
                {status_sub ? (
                    <button className="center-button"
                            onClick={() => handleOpenDownloadModal(collection.id)}>Download</button>
                ) : (<button className="center-button buy-button adjust-postion" onClick={handleBuy}>Subscribe
                  and save</button>)}

                <img
                    src={collection.image ? `${config.imageBaseUrl}${collection.image}` : '/images/electric-cataloge.png'}
                    alt='' className="catalog-image"/>
                <div className="catalog-info">
                  <h3 className="catalog-item-title">{collection.name}</h3>
                  <p className="catalog-item-date">{new Date(collection.rundate).toLocaleDateString()}</p>
                  {/* <span className="catalog-cart-icon">🛒</span> */}
                </div>
              </div>
          ))}
        </div>
        <h2 className="catalog-title">Free Collections</h2>
        <div className="catalog-items">
          {allCollections.filter((c) => c.is_free).map((collection) => (
              <div className="catalog-item" key={collection.collection_id}>
                <button className="center-button"
                        onClick={() => handleOpenDownloadModal(collection.id)}>Download</button>
                <img
                    src={collection.image ? `${config.imageBaseUrl}${collection.image}` : '/images/electric-cataloge.png'}
                    alt='' className="catalog-image"/>
                <div className="catalog-info">
                  <h3 className="catalog-item-title">{collection.name}</h3>
                  <p className="catalog-item-date">{new Date(collection.rundate).toLocaleDateString()}</p>
                  {/* <span className="catalog-cart-icon">🛒</span> */}
                </div>
              </div>
          ))}
        </div>
      </>)
    } else if (showFreeCollections) {
      console.log("free collections", allCollections.filter((c) => c.is_free))
      return (<><h2 className="catalog-title">Current Editions</h2>
        <div className="catalog-items">
          {allCollections.filter((c) => c.is_free).map((c) => (
              <div className="catalog-item" key={c.collection_id}>
                <button className="center-button"
                        onClick={() => handleOpenDownloadModal(c.id)}>Download</button>
                <img
                    src={c.image ? `${config.imageBaseUrl}${c.image}` : '/images/electric-cataloge.png'}
                    alt='' className="catalog-image"/>
                <div className="catalog-info">
                  <h3 className="catalog-item-title">{c.name}</h3>
                  <p className="catalog-item-date">{new Date(c.rundate).toLocaleDateString()}</p>
                  {/* <span className="catalog-cart-icon">🛒</span> */}
                </div>
              </div>
          ))}
        </div>
      </>)
    }
    return selectedCategory && (
        <>
          {(selectedtagline &&
              <div className='tagline-banner'>
                <img src="/images/idea-icon.svg" alt=""/>
                <p>{selectedtagline}</p>
              </div>
          )}

          <h2 className="catalog-title">Current Editions</h2>
          <div className="catalog-items">
            <div className="catalog-item">
              {status_sub ? (
                  <button className="center-button"
                          onClick={() => handleOpenDownloadModal(collection.id)}>Download</button>
              ) : (<button className="center-button buy-button" onClick={handleBuy}>Subscribe and
                save</button>)}


              <img src={image_path ? `${config.imageBaseUrl}${image_path}` : '/images/electric-cataloge.png'}
                   alt='' className="catalog-image"/>
              {collection ? (
                  <div className="catalog-info">
                    <h3 className="catalog-item-title">{collection.name}</h3>
                    <p className="catalog-item-date">{new Date(collection.rundate).toLocaleDateString()}</p>
                    {/* <span className="catalog-cart-icon">🛒</span> */}
                  </div>
              ) : (
                  <p>Loading collection data...</p>
              )}
            </div>
          </div>
        </>
    )
  }


  return (
    <section id="catalog" className="content-section cataloge-section-complete">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover /> 
      
      <div className="catalog-header">
        <nav className="catalog-nav">
          {categories.filter((c) => !c.is_free).map((category) => (
            <a
              className={`catalog-link ${selectedCategory === category.id ? 'active' : ''}`}
              style={{ cursor: 'pointer' }}
              key={category.id ?? 'all'}
              onClick={() => handleCategoryClick(category.id,category.tagline)}
            >
              {category.name}
            </a>
          ))}
        </nav>
      </div>
      <h2 className="cataloge-mobile-header">{activeCategoryName}</h2>
      <div className="catalog-section">
        <div className="catalog-data">
          {renderContents()}
        </div>
      </div>


      <div className="catalog-section">
        <div className="catalog-data">
          {!showAllCollections && !showFreeCollections && selectedCategory && (
              <>
                <h2 className="catalog-title">Previously Purchased</h2>
                <div className="catalog-items">
                {boughtcollection.map((collection) => (
                  <div className="catalog-item" key={collection.collection_id}>
                    <button className="center-button" onClick={() => handleDownloadPreviouslyPurchasedEdition(collection.id)}>Download</button>
                    <img src={collection.image ? `${config.imageBaseUrl}${collection.image}`:'/images/electric-cataloge.png'} alt='' className="catalog-image" />
                    <div className="catalog-info">
                      <h3 className="catalog-item-title">{collection.name}</h3>
                      <p className="catalog-item-date">{new Date(collection.rundate).toLocaleDateString()}</p>
                      {/* <span className="catalog-cart-icon">🛒</span> */}
                    </div>
                  </div>
                ))}
                </div>
              </>
          )}
        </div>
      </div>
      <BasicModal title={"User Markup Confirmation"} isOpen={isDownloadModalOpen} onClose={() => setIsDownloadModalOpen(false)}>
        <div className="footer-modal">
          Have you added your desired markup configurations before downloading?
          <Button color="primary" onClick={handleDownloadModalConfirmation}>Yes</Button>
          <Button color="secondary" onClick={handleDownloadModalCancellation}>Cancel</Button>
        </div>
      </BasicModal>
    </section>
  );
};

export default Catalog;
