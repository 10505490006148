import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface SubscriptionPlan {
  stripe_price_id_recurring: string;
  stripe_price_id_one_time: string;
  name: string;
  description: string;
  price: number;
}

const Subscriptions: React.FC = () => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [autoRenew, setAutoRenew] = useState<boolean>(true); // Manage auto-renew state
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false); // Manage subscription status

  useEffect(() => {
    const status = localStorage.getItem('status2');
    
    if (status === 'true') {
      setIsSubscribed(true);
    } else {
      axios.get(`${config.apiBaseUrl}/subscription-plans/`)
        .then((response) => {
          setPlans(response.data);
        })
        .catch((error) => {
          console.error('Error fetching subscription plans:', error);
        });
    }
  }, [isSubscribed]);

  const handleBuyClick = (plan: SubscriptionPlan) => {
    const userEmail = localStorage.getItem('email');
    console.log(plan)
    console.log(autoRenew)
    const priceId = autoRenew ? plan.stripe_price_id_recurring : plan.stripe_price_id_one_time;
    console.log(priceId)
    
    axios.post(`${config.apiBaseUrl}/create-checkout-session/`, {
      price_id: priceId,
      is_recurring: autoRenew // Send auto-renew option to backend
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-User-Email': userEmail || ''  // Include the email in the headers
      }
    })
      .then((response) => {
        const sessionurl = response.data.url;
        window.location.href = sessionurl; // Redirect to Stripe checkout
      })
      .catch((error) => {
        console.error('Error creating checkout session:', error);
      });
  };

  const handleCancelSubscription = () => {
    const userEmail = localStorage.getItem('email');
    
    const respnse = axios.post(`${config.apiBaseUrl}/cancel-subscription/`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'X-User-Email': userEmail || ''
      }
    })
      .then(() => {
        console.log('==',respnse)
        // alert('Subscription canceled successfully.');
        toast.success('Subscription canceled successfully.');
        setIsSubscribed(false);
        localStorage.setItem('status2','false');
      })
      .catch((error) => {
        console.error('Error canceling subscription:', error);
      });
  };

  return (
    <section id="subscriptions" className="content-section">
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable pauseOnHover /> 
    <div className="subscription-area">
      <div className="subscription-container">
        <h1 className="subscription-title">Subscriptions</h1>
        <div className="form-area-line">
          <hr />
        </div>
        <div className="subs-info">
          {isSubscribed ? (
            
            <div className="cart-box cancel-box">
              <div className="info-cart">
                <h4>Your Active Subscription</h4>
                {/* <p>You are currently subscribed to a plan.</p> <br /> */}
                <p><span style={{color:'red'}}>Note:</span> if you cancel a subscrition we will cancel you recurring payment but you can download lists till your subscription period end.</p>
              </div>
              <div className="">
                <button className=''
                  style={{ background: 'red', color: 'white', padding: '5px 10px', borderRadius: '5px' }}
                  type="button"
                  onClick={handleCancelSubscription}
                >
                  Cancel Subscription
                </button>
              </div>
            </div>
          ) : (
            <>
              <p className="p-cart">Your Subscription cart</p>
              {/* <p className="p-cart-2">You have {plans.length} items in your cart</p> */}
              {plans.map((plan) => (
                <div className="cart-box" key={plan.stripe_price_id_recurring}>
                  <div className="image">
                    <img src="/images/logo.png" alt="Subscription" />
                  </div>
                  <div className="info-cart">
                    <h4>{plan.name}</h4>
                    <p>{plan.description}</p>
                    <span className="inp">
                      <input
                        type="checkbox"
                        id={`auto-purchase-${plan.stripe_price_id_recurring}`}
                        checked={autoRenew}
                        onChange={() => setAutoRenew(!autoRenew)}
                      />
                      <label htmlFor={`auto-purchase-${plan.stripe_price_id_recurring}`}>
                        Auto-renew next subscriptions
                      </label>
                    </span>
                  </div>
                  <div className="price-cart">
                      <p>${plan.price}</p>
                      <button className=''
                              style={{background: 'blue', color: 'white', padding: '5px 10px', borderRadius: '5px'}}
                              type="button"
                              onClick={() => handleBuyClick(plan)}
                      >
                        Buy
                      </button>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  </section>
);
};


export default Subscriptions;
